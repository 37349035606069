import React, { useState, useEffect } from 'react';
import './styles.css';

const Animation2 = () => {
    const [showButton1, setShowButton1] = useState(false);
    const [showButton2, setShowButton2] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);
    
    useEffect(() => {

        const timer1 = setTimeout(() => {
            setShowButton1(true);
        }, 1000);

        const timer2 = setTimeout(() => {
            setShowButton2(true);
        }, 3000);

        const timer3 = setTimeout(() => {
            setHideButtons(true);
        }, 6000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
        };
    }, []);

    return (
        <div className='animation2'>
            <div className={`loading-container ${showButton1 && !hideButtons ? 'show' : 'hidden'}`}>
                <div className="image-wrapper">
                    <img
                        id="loading"
                        src={`${process.env.PUBLIC_URL}/images/loading.svg`}
                        alt="loading"
                        className={'rotating'}
                    />
                    <img
                        id="robot"
                        src={`${process.env.PUBLIC_URL}/images/robot.svg`}
                        alt="robot"
                    />
                </div>
            </div>
            <div className={`text ${showButton2 && !hideButtons ? 'show' : 'hidden'}`}>
                <p>A inteligência artificial vai buscar os códigos médicos de acordo com o laudo inserido</p>
            </div>
        </div>
    );
};

export default Animation2;
