import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import Cards from "./cards.json";
import "./styles.css";

const Animation4 = () => {
    const [titleVisible, setTitleVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setTitleVisible(true);
        }, 1000);
    }, []);

    return (
        <div className='animation4'>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Lottie
                    animationData={Cards}
                    loop={false}
                    autoplay={true}
                    style={{ height: 300, width: 300, aspectRatio: "xMidYMid slice" }} // Ajuste do tamanho diretamente no style
                />
            </div>
            <div className={`text ${titleVisible ? 'visible' : 'hidden'}`}>
                <p>Pedido médico pronto para ser baixado</p>
            </div>
        </div>
    );
}

export default Animation4;
